import React, { useState } from 'react';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'https://f-oracle-backend.onrender.com/api';

const CreateCartItem = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const createCartItem = async () => {
    setIsLoading(true);
    setError(null);
    setResult(null);

    try {
      const response = await axios.post(`${API_URL}/imaterialise/create-cart-item`);
      setResult(response.data);
    } catch (error) {
      console.error('Error creating cart item:', error);
      setError(error.response?.data?.error || 'An error occurred while creating the cart item');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <h2>Create Cart Item with Pre-existing File</h2>
      <button onClick={createCartItem} disabled={isLoading}>
        {isLoading ? 'Creating...' : 'Create Cart Item'}
      </button>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {result && (
        <div>
          <h3>Cart Item Created:</h3>
          <pre>{JSON.stringify(result, null, 2)}</pre>
        </div>
      )}
    </div>
  );
};

export default CreateCartItem;