import React, { useState, useEffect } from 'react';
import { Box, Title, Button, LoadingOverlay, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import axios from '../../src/axiosConfig';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import Coordinates from './Coordinates';

function YourChart({ user, onUserUpdate }) {
  const [chartData, setChartData] = useState({
    birthDate: null,
    country: '',
    city: '',
    longitude: '',
    latitude: '',
  });
  const [horoscopeData, setHoroscopeData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      setChartData({
        birthDate: user.birth_datetime ? new Date(user.birth_datetime) : null,
        country: user.country || '',
        city: user.city || '',
        longitude: user.longitude || '',
        latitude: user.latitude || '',
      });
    }
  }, [user]);

  const handleChartDataChange = (field) => (value) => {
    setChartData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleCoordinatesChange = (latitude, longitude) => {
    setChartData(prevData => ({ ...prevData, latitude, longitude }));
  };

  const fetchHoroscopeData = async () => {
    if (!chartData.birthDate || !chartData.longitude || !chartData.latitude) {
      showNotification({
        title: 'Error',
        message: 'Please fill in all fields',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.post('/saved-horoscopes/generate-data', {
        input_date: new Date().toISOString(), // Current date for natal chart
        birth_date: chartData.birthDate.toISOString(),
        longitude: parseFloat(chartData.longitude),
        latitude: parseFloat(chartData.latitude),
      });

      setHoroscopeData(response.data);
      showNotification({
        title: 'Success',
        message: 'Horoscope data generated successfully',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
    } catch (error) {
      console.error('Horoscope data generation failed', error);
      showNotification({
        title: 'Error',
        message: 'Failed to generate horoscope data: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateChart = async () => {
    if (!chartData.birthDate || !chartData.longitude || !chartData.latitude) {
      showNotification({
        title: 'Error',
        message: 'Please fill in all fields',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    setLoading(true);
    try {
      const response = await axios.put(`/users/${user.id}`, {
        birth_datetime: chartData.birthDate.toISOString(),
        country: chartData.country,
        city: chartData.city,
        longitude: parseFloat(chartData.longitude),
        latitude: parseFloat(chartData.latitude),
      });

      console.log('Chart updated successfully', response.data);
      showNotification({
        title: 'Success',
        message: 'Your natal chart has been updated and saved',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });

      if (onUserUpdate) {
        onUserUpdate(response.data);
      }

      // Fetch horoscope data after updating user information
      await fetchHoroscopeData();
    } catch (error) {
      console.error('Chart update failed', error);
      showNotification({
        title: 'Error',
        message: 'Chart update failed: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box position="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Title order={2}>Your Natal Chart</Title>
      <Box p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
        <DateTimePicker
          label="Birth Date and Time"
          placeholder="Pick date and time"
          value={chartData.birthDate}
          onChange={handleChartDataChange('birthDate')}
          clearable
        />
        <Coordinates
          initialCountry={chartData.country}
          initialCity={chartData.city}
          initialLongitude={chartData.longitude}
          initialLatitude={chartData.latitude}
          onCoordinatesChange={handleCoordinatesChange}
        />
        <Button onClick={handleUpdateChart} mt="md" fullWidth>Update Your Natal Chart</Button>
      </Box>
      {horoscopeData && (
        <Box mt="xl">
          <Title order={3}>Your Horoscope Data</Title>
          <Text>Sun: {horoscopeData.sun.toFixed(2)}°</Text>
          <Text>Moon: {horoscopeData.moon.toFixed(2)}°</Text>
          <Text>Mercury: {horoscopeData.mercury.toFixed(2)}°</Text>
          <Text>Venus: {horoscopeData.venus.toFixed(2)}°</Text>
          <Text>Mars: {horoscopeData.mars.toFixed(2)}°</Text>
          <Text>Jupiter: {horoscopeData.jupiter.toFixed(2)}°</Text>
          <Text>Saturn: {horoscopeData.saturn.toFixed(2)}°</Text>
          <Title order={4} mt="md">Aspects</Title>
          {horoscopeData.aspects.map((aspect, index) => (
            <Text key={index}>{aspect.planet1} {aspect.aspect} {aspect.planet2} ({aspect.orb.toFixed(2)}°)</Text>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default YourChart;