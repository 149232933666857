import React from 'react';
import { Box, Text, Button, Stack, Title } from '@mantine/core';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell } from 'recharts';

const SavedHoroscopeDisplay = ({ savedHoroscopes, handleDeleteHoroscope }) => {
  const extractPercentages = (chatbotResponse) => {
    const attributes = ['Intuition', 'Energi', 'Tur', 'Karisma'];
    return attributes.map(attribute => {
      const match = chatbotResponse.match(new RegExp(`${attribute}: (\\d+)%`));
      return {
        attribute,
        value: match ? parseInt(match[1], 10) : 0
      };
    });
  };

  const formatChatbotResponse = (response) => {
    const paragraphs = response.split('\n').filter(p => p.trim() !== '');
    return paragraphs.map((paragraph, index) => {
      if (paragraph.includes(':')) {
        const [key, value] = paragraph.split(':');
        return (
          <Text key={index} mb={8}>
            <Text component="span" weight={700}>{key}:</Text>
            <Text component="span">{value}</Text>
          </Text>
        );
      }
      return <Text key={index} mb={8}>{paragraph}</Text>;
    });
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{ backgroundColor: 'white', padding: '5px', border: '1px solid #ccc' }}>
          <p>{`${label}: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  const barColors = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042'];

  return (
    <Stack>
      {savedHoroscopes.map((horoscope) => {
        const chartData = extractPercentages(horoscope.chatbot_response);
        console.log('Chart Data:', chartData); // Debugging log

        return (
          <Box key={horoscope.id} p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
            <Title order={4} mb="md">Horoscope Details</Title>
            <Text><Text component="span" weight={700}>Type:</Text> {horoscope.horoscope_type}</Text>
            <Text><Text component="span" weight={700}>Input Date:</Text> {new Date(horoscope.input_date).toLocaleString()}</Text>
            <Text><Text component="span" weight={700}>Birth Date:</Text> {new Date(horoscope.birth_date).toLocaleString()}</Text>
            <Text><Text component="span" weight={700}>Latitude:</Text> {horoscope.latitude}</Text>
            <Text><Text component="span" weight={700}>Longitude:</Text> {horoscope.longitude}</Text>
            
            <Box mt="lg">
              <Title order={4} mb="sm">Horoscope Interpretation</Title>
              {formatChatbotResponse(horoscope.chatbot_response)}
            </Box>
            
            {horoscope.horoscope_type === 'natal' && (
              <Box mt="lg" h={300}>
                <Title order={4} mb="sm">Natal Chart Analysis</Title>
                <ResponsiveContainer width="100%" height="100%">
                  <BarChart
                    data={chartData}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="attribute" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip content={<CustomTooltip />} />
                    <Bar dataKey="value">
                      {chartData.map((entry, index) => {
                        console.log(`Bar ${index} color:`, barColors[index]); // Debugging log
                        return <Cell key={`cell-${index}`} fill={barColors[index]} />;
                      })}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            )}
            
            <Button color="red" size="sm" onClick={() => handleDeleteHoroscope(horoscope.id)} mt="lg">Delete</Button>
          </Box>
        );
      })}
    </Stack>
  );
};

export default SavedHoroscopeDisplay;