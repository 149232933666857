// src/components/AuthenticationModal.js

import React, { useState } from 'react';
import {
  Modal,
  Button,
  TextInput,
  PasswordInput,
  Checkbox,
  Group,
  Box,
  Notification,
  LoadingOverlay,
  Divider,
} from '@mantine/core';
import { IconCheck, IconX } from '@tabler/icons-react'; // Removed IconBrandAuth0 since SSO is commented out

// Environment variables
const API_URL = process.env.REACT_APP_API_URL;
// const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN; // Commented out since SSO is commented out
// const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID; // Commented out since SSO is commented out
const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://fateoracle.onrender.com';

function AuthenticationModal({ opened, onClose, onLogin }) {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    agreeToTerms: false,
  });
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({
    message: '',
    color: '',
    icon: null,
  });

  /**
   * Handles input changes in the form fields.
   * @param {string} field - The field name in the formData state.
   * @returns {function} - An event handler function.
   */
  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  /**
   * Handles user registration.
   * Sends a POST request to the backend API to register the user.
   * Automatically logs in the user upon successful registration.
   */
  const handleRegister = async () => {
    if (formData.password !== formData.confirmPassword) {
      setNotification({
        message: 'Passwords do not match',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }

    const registrationData = {
      username: formData.userName,
      email: formData.email,
      password: formData.password,
    };

    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/auth/register`, { // Corrected endpoint
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || `HTTP error! status: ${response.status}`);
      }

      setNotification({
        message: 'Registration successful',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });

      // Automatically log in after successful registration
      await handleLogin();
    } catch (error) {
      setNotification({
        message: `Registration failed: ${error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Handles user login.
   * Sends a POST request to the backend API to authenticate the user.
   * Calls the onLogin callback upon successful authentication.
   */
  const handleLogin = async () => {
    try {
      setLoading(true);
      const response = await fetch(`${API_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
        }),
      });

      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        const errorText = await response.text();
        throw new Error(`Unexpected response: ${errorText}`);
      }

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || `HTTP error! status: ${response.status}`);
      }

      // Store the token
      localStorage.setItem('token', data.token);

      setNotification({
        message: 'Login successful',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });

      onLogin(data); // Pass user data to parent component
      onClose(); // Close the modal
    } catch (error) {
      setNotification({
        message: `Login failed: ${error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  /**
   * Initiates the Single Sign-On (SSO) login process via Auth0.
   * Redirects the user to the Auth0 authorization endpoint.
   */
  // const handleSSOLogin = () => {
  //   if (!AUTH0_DOMAIN || !AUTH0_CLIENT_ID) {
  //     console.error('Auth0 configuration is missing');
  //     setNotification({
  //       message: 'SSO login is not configured properly',
  //       color: 'red',
  //       icon: <IconX size={18} />
  //     });
  //     return;
  //   }

  //   // Remove any trailing slash from BASE_URL
  //   const baseUrl = BASE_URL.replace(/\/$/, '');
  //   const redirectUri = `${baseUrl}/callback`;
  //   const auth0LoginUrl = `https://${AUTH0_DOMAIN}/authorize?response_type=code&client_id=${AUTH0_CLIENT_ID}&redirect_uri=${encodeURIComponent(redirectUri)}&scope=openid%20profile%20email`;

  //   console.log('Auth0 Login URL:', auth0LoginUrl);

  //   window.location.href = auth0LoginUrl;
  // };


  return (
    <Modal opened={opened} onClose={onClose} title="Authentication" centered size="md">
      <Box pos="relative">
        {loading && <LoadingOverlay visible={loading} overlayBlur={0} />}
        {notification.message && (
          <Notification
            color={notification.color}
            icon={notification.icon}
            onClose={() => setNotification({ message: '', color: '', icon: null })}
            mb="md"
          >
            {notification.message}
          </Notification>
        )}

        {/* SSO Button commented out */}
        {/* <Button
          fullWidth
          leftIcon={<IconBrandAuth0 size={18} />}
          onClick={handleSSOLogin}
          mb="md"
          variant="outline"
          color="dark"
        >
          Login with SSO
        </Button> 

        <Divider my="md" label="Or continue with email" labelPosition="center" />*/}

        <TextInput
          label="Username"
          placeholder="Your username"
          required
          value={formData.userName}
          onChange={handleChange('userName')}
        />
        <TextInput
          label="Email"
          placeholder="Your email"
          required
          value={formData.email}
          onChange={handleChange('email')}
          mt="sm"
        />
        <PasswordInput
          label="Password"
          placeholder="Password"
          required
          value={formData.password}
          onChange={handleChange('password')}
          mt="sm"
        />
        <PasswordInput
          label="Confirm Password"
          placeholder="Confirm password"
          required
          value={formData.confirmPassword}
          onChange={handleChange('confirmPassword')}
          mt="sm"
        />
        <Checkbox
          label="I agree to the terms and conditions"
          checked={formData.agreeToTerms}
          onChange={(e) => setFormData({ ...formData, agreeToTerms: e.currentTarget.checked })}
          mt="md"
        />
        <Group position="apart" mt="md">
          <Button
            onClick={handleRegister}
            disabled={!formData.agreeToTerms}
            variant="filled"
            color="blue"
          >
            Register
          </Button>
          <Button onClick={handleLogin} variant="outline" color="blue">
            Login
          </Button>
        </Group>
      </Box>
    </Modal>
  );
}

export default AuthenticationModal;
