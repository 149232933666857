import React, { useState } from 'react';
import { Tabs, Menu } from '@mantine/core';
import { IconStar, IconLogin, IconSettings } from '@tabler/icons-react';
import AuthenticationModal from './AuthenticationModal';
import UserSettings from './UserSettings';
import HoroscopePage from './HoroscopePage';
import NewSearch from './NewSearch';
import YourChart from './YourChart';
import SavedCharts from './SavedCharts';

function LandingTabs({ user, onLogin, onLogout, subscriptionStatus }) {
  const [modalOpened, setModalOpened] = useState(false);
  const [activeTab, setActiveTab] = useState('collective');
  const [pendingAction, setPendingAction] = useState(null);

  const handleTabChange = (value) => {
    if (value === 'login') {
      if (!user) {
        setModalOpened(true);
      } else {
        setActiveTab('userSettings');
      }
    } else {
      setActiveTab(value);
      setModalOpened(false);
    }
    setPendingAction(null);
  };

  const handleMenuItemClick = (action) => {
    if (user) {
      setActiveTab(action);
    } else {
      setPendingAction(action);
      setModalOpened(true);
    }
  };

  const handleLogin = (userData) => {
    onLogin(userData);
    if (pendingAction) {
      setActiveTab(pendingAction);
    } else {
      setActiveTab('userSettings');
    }
    setPendingAction(null);
    setModalOpened(false);
  };

  return (
    <Tabs value={activeTab} onTabChange={handleTabChange}>
      <Tabs.List grow>
        <Menu trigger="hover" openDelay={100} closeDelay={400} withinPortal style={{ flex: '1 0 40%' }}>
          <Menu.Target>
            <Tabs.Tab value="collective" leftSection={<IconStar size={14} />} style={{ width: '100%' }}>
              Collective
            </Tabs.Tab>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Horoscope Types</Menu.Label>
            <Menu.Item onClick={() => setActiveTab('collective')}>General</Menu.Item>
            <Menu.Divider />
            <Menu.Label>Special</Menu.Label>
            <Menu.Item onClick={() => setActiveTab('politics')}>Politics</Menu.Item>
            <Menu.Item onClick={() => setActiveTab('opportunities')}>Opportunities</Menu.Item>
            <Menu.Item onClick={() => setActiveTab('warnings')}>Warnings</Menu.Item>
            <Menu.Item onClick={() => setActiveTab('travel')}>Travel</Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Menu trigger="hover" openDelay={100} closeDelay={400} withinPortal style={{ flex: '1 0 40%' }}>
          <Menu.Target>
            <Tabs.Tab value="personal" leftSection={<IconStar size={14} />} style={{ width: '100%' }}>
              Personal
            </Tabs.Tab>
          </Menu.Target>
          <Menu.Dropdown>
            <Menu.Label>Personal Horoscopes</Menu.Label>
            <Menu.Item onClick={() => handleMenuItemClick('newSearch')}>
              New Search {!user && '(Login required)'}
            </Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('yourChart')}>
              Your Chart {!user && '(Login required)'}
            </Menu.Item>
            <Menu.Item onClick={() => handleMenuItemClick('savedCharts')}>
              Your Saved Charts {!user && '(Login required)'}
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>

        <Tabs.Tab 
          value="login" 
          leftSection={user ? <IconSettings size={14} /> : <IconLogin size={14} />}
          style={{ flex: '1 0 20%' }}
          onClick={() => handleTabChange('login')}
        >
          {user ? 'User Settings' : 'Login'}
        </Tabs.Tab>
      </Tabs.List>

      {/* Render the appropriate panel based on the active tab */}
      <Tabs.Panel value="collective" pt="xs">
        <div>Collective content goes here...</div>
      </Tabs.Panel>

      <Tabs.Panel value="newSearch" pt="xs">
        <NewSearch user={user} />
      </Tabs.Panel>

      <Tabs.Panel value="yourChart" pt="xs">
        <YourChart user={user} />
      </Tabs.Panel>

      <Tabs.Panel value="savedCharts" pt="xs">
        <SavedCharts user={user} />
      </Tabs.Panel>

      <Tabs.Panel value="userSettings" pt="xs">
        {/* Only render UserSettings if user is not null */}
        {user ? (
          <UserSettings user={user} onLogout={onLogout} subscriptionStatus={subscriptionStatus} />
        ) : (
          <div>Please log in to access user settings.</div>
        )}
      </Tabs.Panel>

      {/* Handle the login modal */}
      <AuthenticationModal 
        opened={modalOpened} 
        onClose={() => {
          setModalOpened(false);
          setPendingAction(null);
        }} 
        onLogin={handleLogin}
      />
    </Tabs>
  );
}

export default LandingTabs;