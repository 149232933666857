import React, { useState, useEffect } from 'react';
import { Box, Title, Text, Button, Group, Alert, TextInput, PasswordInput, Stack, LoadingOverlay } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import { IconCheck, IconAlertCircle, IconX } from '@tabler/icons-react';
import { showNotification } from '@mantine/notifications';
import axios from '../../src/axiosConfig';
import { useStripe } from '@stripe/react-stripe-js';
import Coordinates from './Coordinates';  // Import the Coordinates component

function UserSettings({ user, onLogout, subscriptionStatus, onUserUpdate }) {
  const [formData, setFormData] = useState({
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
    longitude: '',
    latitude: '',
    birthDatetime: null,
  });
  
  const [loading, setLoading] = useState(false);
  const [userSubscription, setUserSubscription] = useState(user?.subscription_status || 'none');
  const stripe = useStripe();

  useEffect(() => {
    if (user) {
      setFormData({
        userName: user.username || '',
        email: user.email || '',
        password: '',
        confirmPassword: '',
        longitude: user.longitude || '',
        latitude: user.latitude || '',
        birthDatetime: user.birth_datetime ? new Date(user.birth_datetime) : null,
      });
      setUserSubscription(user.subscription_status || 'none');
    }
  }, [user]);

  const handleChange = (field) => (event) => {
    setFormData({ ...formData, [field]: event.target.value });
  };

  const handleDateTimeChange = (value) => {
    setFormData({ ...formData, birthDatetime: value });
  };

  const handleCoordinatesChange = (latitude, longitude) => {
    setFormData(prevData => ({ ...prevData, latitude, longitude }));
  };

  const handleUpdate = async () => {
    if (formData.password && formData.password !== formData.confirmPassword) {
      showNotification({
        message: 'Passwords do not match',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }
  
    try {
      setLoading(true);
      const updateData = {};
  
      // Only include fields that have changed
      if (formData.userName !== user.username) updateData.username = formData.userName;
      if (formData.email !== user.email) updateData.email = formData.email;
      if (formData.password) updateData.password = formData.password;
      if (formData.longitude !== user.longitude) updateData.longitude = formData.longitude ? parseFloat(formData.longitude) : null;
      if (formData.latitude !== user.latitude) updateData.latitude = formData.latitude ? parseFloat(formData.latitude) : null;
      if (formData.birthDatetime !== user.birth_datetime) updateData.birth_datetime = formData.birthDatetime ? formData.birthDatetime.toISOString() : null;
  
      console.log('Sending update data:', updateData);
  
      const response = await axios.put(`/users/${user.id}`, updateData);
  
      console.log('Update response:', response.data);
  
      showNotification({
        message: 'Update successful',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
  
      onUserUpdate(response.data);
    } catch (error) {
      console.error('Update failed', error.response || error);
      showNotification({
        message: `Update failed: ${error.response?.data?.message || error.message}`,
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubscribe = async () => {
    try {
      console.log('Starting subscription process');
      const response = await axios.post('/create-subscription', {
        userId: user.id,
        planId: 'basic'
      });
      console.log('Received response from server:', response.data);

      if (response.data.sessionId) {
        console.log('Redirecting to Stripe checkout');
        const result = await stripe.redirectToCheckout({
          sessionId: response.data.sessionId
        });

        if (result.error) {
          throw new Error(result.error.message);
        }
        console.log('Checkout successful, awaiting redirect');
      } else {
        throw new Error('No session ID received from server');
      }
    } catch (error) {
      console.error('Subscription failed', error);
      showNotification({
        title: 'Error',
        message: 'Subscription failed: ' + (error.response?.data?.error || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    }
  };

  return (
    <Box position="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      {subscriptionStatus === 'success' && (
        <Alert icon={<IconCheck size={16} />} title="Subscription Successful!" color="green" mb="md">
          Thank you for subscribing to our service.
        </Alert>
      )}
      {subscriptionStatus === 'canceled' && (
        <Alert icon={<IconAlertCircle size={16} />} title="Subscription Canceled" color="red" mb="md">
          Your subscription process was canceled.
        </Alert>
      )}

      <Title order={2}>User Settings</Title>
      <TextInput
        label="Username"
        placeholder="Your Username"
        required
        value={formData.userName}
        onChange={handleChange('userName')}
      />
      <TextInput
        label="Email"
        placeholder="Your email"
        required
        value={formData.email}
        onChange={handleChange('email')}
      />
      <Coordinates
        initialLongitude={formData.longitude}
        initialLatitude={formData.latitude}
        onCoordinatesChange={handleCoordinatesChange}
      />
      <DateTimePicker
        label="Birth Date and Time"
        placeholder="Pick date and time"
        value={formData.birthDatetime}
        onChange={handleDateTimeChange}
        clearable
      />
      <PasswordInput
        label="New Password"
        placeholder="New password"
        value={formData.password}
        onChange={handleChange('password')}
      />
      <PasswordInput
        label="Confirm Password"
        placeholder="Confirm new password"
        value={formData.confirmPassword}
        onChange={handleChange('confirmPassword')}
      />
      <Group position="apart" mt="xl">
        <Button color="red" onClick={onLogout}>Logout</Button>
        <Button onClick={handleUpdate}>Update</Button>
      </Group>

      <Title order={2} mt="xl">Subscription</Title>
      <Box p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
        <Text>Current Status: {userSubscription || 'No active subscription'}</Text>
        <Stack mt="md">
          <Button onClick={handleSubscribe} disabled={userSubscription === 'basic'}>
            Subscribe to Basic Plan
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default UserSettings;