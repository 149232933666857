// src/components/Coordinates.js
import React, { useState, useEffect } from 'react';
import { Box, Select, NumberInput } from '@mantine/core';
import axios from '../../src/axiosConfig';
import { showNotification } from '@mantine/notifications';
import { IconX } from '@tabler/icons-react';
import { useCountryContext } from '../contexts/CountryContext';

function Coordinates({ initialCountry, initialCity, initialLongitude, initialLatitude, onCoordinatesChange }) {
  const { countries, loading, error } = useCountryContext();
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(initialCountry || '');
  const [selectedCity, setSelectedCity] = useState(initialCity || '');
  const [longitude, setLongitude] = useState(initialLongitude || '');
  const [latitude, setLatitude] = useState(initialLatitude || '');

  useEffect(() => {
    if (initialCountry) {
      fetchCities(initialCountry);
    }
  }, [initialCountry]);

  useEffect(() => {
    setSelectedCountry(initialCountry || '');
    setSelectedCity(initialCity || '');
    setLongitude(initialLongitude || '');
    setLatitude(initialLatitude || '');
  }, [initialCountry, initialCity, initialLongitude, initialLatitude]);


  const fetchCities = async (country) => {
    try {
      const response = await axios.post('https://countriesnow.space/api/v0.1/countries/cities', {
        country: countries.find(c => c.value === country).label
      });
      if (response.data.data) {
        const sortedCities = response.data.data.sort((a, b) => a.localeCompare(b));
        setCities(sortedCities.map(city => ({ value: city, label: city })));
      }
    } catch (error) {
      console.error('Error fetching cities:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to fetch cities',
        color: 'red',
        icon: <IconX size={18} />,
      });
    }
  };

  const fetchCoordinates = async (city, country) => {
    try {
      const response = await axios.get(`https://nominatim.openstreetmap.org/search?format=json&q=${city},${country}`);
      if (response.data.length > 0) {
        const newLatitude = response.data[0].lat;
        const newLongitude = response.data[0].lon;
        setLatitude(newLatitude);
        setLongitude(newLongitude);
        onCoordinatesChange(newLatitude, newLongitude);
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to fetch coordinates',
        color: 'red',
        icon: <IconX size={18} />,
      });
    }
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
    setSelectedCity('');
    setCities([]);
    fetchCities(value);
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    fetchCoordinates(value, countries.find(c => c.value === selectedCountry).label);
  };

  const handleCoordinateChange = (type) => (value) => {
    if (type === 'longitude') {
      setLongitude(value);
    } else {
      setLatitude(value);
    }
    onCoordinatesChange(latitude, longitude);
  };

  return (
    <Box>
      <Select
        label="Country"
        placeholder="Select a country"
        data={countries}
        value={selectedCountry}
        onChange={handleCountryChange}
        searchable
        nothingFound="No country found"
        maxDropdownHeight={280}
        disabled={loading}
        error={error}
      />
      <Select
        label="City"
        placeholder="Select a city"
        data={cities}
        value={selectedCity}
        onChange={handleCityChange}
        disabled={!selectedCountry}
        searchable
        nothingFound="No city found"
        maxDropdownHeight={280}
      />
      <NumberInput
        label="Longitude"
        placeholder="Longitude"
        precision={6}
        value={longitude}
        onChange={handleCoordinateChange('longitude')}
      />
      <NumberInput
        label="Latitude"
        placeholder="Latitude"
        precision={6}
        value={latitude}
        onChange={handleCoordinateChange('latitude')}
      />
    </Box>
  );
}

export default Coordinates;