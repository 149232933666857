import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import * as THREE from 'three';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import {
  Container, Paper, Button, Group, Text, Loader, Center, Title, Flex, Box, Divider,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import CreateCartItem from './CreateCartItem';

const API_URL = process.env.REACT_APP_API_URL || 'https://f-oracle-backend.onrender.com/api';

const ShopM = () => {
  const [modelPrice, setModelPrice] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isModelLoading, setIsModelLoading] = useState(false);
  const [error, setError] = useState(null);
  const [objString, setObjString] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [modelId, setModelId] = useState(null);
  const [modelDimensions, setModelDimensions] = useState(null);
  const [printLabLink, setPrintLabLink] = useState(null);

  const mountRef = useRef(null);
  const sceneRef = useRef(new THREE.Scene());
  const cameraRef = useRef(null);
  const rendererRef = useRef(null);
  const controlsRef = useRef(null);

  useEffect(() => {
    const width = mountRef.current.clientWidth;
    const height = mountRef.current.clientHeight;

    sceneRef.current.background = new THREE.Color(0xffffff);

    const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000);
    camera.position.z = 5;
    cameraRef.current = camera;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    mountRef.current.appendChild(renderer.domElement);
    rendererRef.current = renderer;

    const controls = new OrbitControls(camera, renderer.domElement);
    controlsRef.current = controls;

    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(sceneRef.current, camera);
    };

    animate();

    return () => {
      renderer.dispose();
      controls.dispose();
    };
  }, []);

  const generateEarring = async () => {
    try {
      setIsLoading(true);
      setIsModelLoading(false);
      setError(null);

      clearScene();

      const response = await axios.post(`${API_URL}/generate-earring`, {
        date: selectedDate.toISOString(),
        withMirror: true
      });

      if (response.data.success) {
        setObjString(response.data.objString);
        setIsLoading(false);
        setIsModelLoading(true);
        loadModel(response.data.objString);
      } else {
        setError('Failed to generate earring model: ' + (response.data.error || 'Unknown error'));
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error generating earring:', error);
      setError('Failed to generate earring model: ' + (error.response?.data?.error || error.message));
      setIsLoading(false);
    }
  };

  const clearScene = () => {
    sceneRef.current.children = sceneRef.current.children.filter(child => child instanceof THREE.Light);
  };

  const loadModel = (objString) => {
    const loader = new OBJLoader();
    const object = loader.parse(objString);

    const silverMaterial = new THREE.MeshStandardMaterial({
      color: 0xc0c0c0,
      metalness: 0.5,
      roughness: 0.6,
    });

    object.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.material = silverMaterial;
      }
    });

    sceneRef.current.add(object);

    const box = new THREE.Box3().setFromObject(object);
    const center = box.getCenter(new THREE.Vector3());
    object.position.sub(center);

    const size = box.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = cameraRef.current.fov * (Math.PI / 180);
    let cameraZ = Math.abs(maxDim / 2 / Math.tan(fov / 2));
    cameraRef.current.position.z = cameraZ * 1.5;

    const minZ = box.min.z;
    const cameraToFarEdge = (minZ < 0) ? -minZ + cameraZ : cameraZ - minZ;
    cameraRef.current.far = cameraToFarEdge * 3;
    cameraRef.current.updateProjectionMatrix();

    adjustLighting();

    setIsModelLoading(false);
  };

  const adjustLighting = () => {
    sceneRef.current.children = sceneRef.current.children.filter(child => !(child instanceof THREE.Light));

    const ambientLight = new THREE.AmbientLight(0xffffff, 1.5);
    sceneRef.current.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.2);
    directionalLight.position.set(5, 5, 5);
    sceneRef.current.add(directionalLight);
  };

  const uploadModel = async () => {
    if (!objString) {
      setError('No model generated yet.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const blob = new Blob([objString], { type: 'text/plain' });
      const file = new File([blob], 'model.obj', { type: 'text/plain' });

      const formData = new FormData();
      formData.append('file', file);

      const response = await axios.post(`${API_URL}/imaterialise/upload-model`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setModelId(response.data.modelID);
      setModelDimensions(response.data.dimensions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error uploading model:', error);
      setError('Failed to upload model: ' + (error.response?.data?.error || error.message));
      setIsLoading(false);
    }
  };

  const getPrice = async () => {
    if (!modelId || !modelDimensions) {
      setError('Model not uploaded yet.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_URL}/imaterialise/get-price`, {
        modelId,
        dimensions: modelDimensions
      });

      setModelPrice(response.data.price);
      setIsLoading(false);
    } catch (error) {
      console.error('Error getting price:', error);
      setError('Failed to get price: ' + (error.response?.data?.error || error.message));
      setIsLoading(false);
    }
  };

  const createCart = async () => {
    if (!modelId || !modelPrice) {
      setError('Price not calculated yet.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const response = await axios.post(`${API_URL}/imaterialise/create-cart`, {
        modelId,
        price: modelPrice
      });

      setPrintLabLink(response.data.printLabLink);
      setIsLoading(false);
    } catch (error) {
      console.error('Error creating cart:', error);
      setError('Failed to create cart: ' + (error.response?.data?.error || error.message));
      setIsLoading(false);
    }
  };

  const checkBackendConnectivity = async () => {
    try {
      const response = await axios.get(`${API_URL}/imaterialise/check`);
      console.log('Backend connectivity check response:', response.data);
      return response.data.status === 'ok';
    } catch (error) {
      console.error('Error checking backend connectivity:', error);
      return false;
    }
  };
  
  // Usage example (e.g., in your ShopM component):
  useEffect(() => {
    const checkConnectivity = async () => {
      const isConnected = await checkBackendConnectivity();
      if (isConnected) {
        console.log('Successfully connected to the backend');
      } else {
        console.error('Failed to connect to the backend');
      }
    };
  
    checkConnectivity();
  }, []);

  return (
    <Container>
      <Paper shadow="md" padding="lg" withBorder>
        <Title align="center" order={2} mb="xl">
          Spiral Earring Model
        </Title>
        <Divider my="sm" />

        <Flex justify="space-between" align="center" mb="lg" direction={{ base: 'column', sm: 'row' }}>
          <DatePickerInput
            label='Select a date for the model'
            onChange={(date) => setSelectedDate(date)}
            value={selectedDate}
            size="md"
            mx="sm"
          />
          <Button onClick={generateEarring} disabled={isLoading} m={10} w={200} size="md">
            {isLoading ? <Loader size="sm" color="white" /> : 'Generate Earring'}
          </Button>
        </Flex>

        <Box ref={mountRef} style={{ width: '100%', height: '400px', marginTop: '20px', position: 'relative' }}>
          {isModelLoading && (
            <Center style={{ height: '100%' }}>
              <Loader size="lg" />
            </Center>
          )}
          {!isModelLoading && error && <Text color="red">{error}</Text>}
        </Box>

        {objString && (
          <>
            <Divider my="sm" />
            <Flex justify="center" align="center" direction="column">
              <Button onClick={uploadModel} disabled={isLoading} size="md" mt="md">
                {isLoading ? <Loader size="sm" color="white" /> : 'Upload Model'}
              </Button>
              {modelId && (
                <Button onClick={getPrice} disabled={isLoading} size="md" mt="md">
                  {isLoading ? <Loader size="sm" color="white" /> : 'Get Price'}
                </Button>
              )}
              {modelPrice && (
                <Group position="center" direction="column" spacing="sm" mt="md">
                  <Text size="lg">Price: {modelPrice}</Text>
                  <Button onClick={createCart} disabled={isLoading} size="md">
                    {isLoading ? <Loader size="sm" color="white" /> : 'Create Cart'}
                  </Button>
                </Group>
              )}
              {printLabLink && (
                <Button component="a" href={printLabLink} target="_blank" rel="noopener noreferrer" size="md" mt="md">
                  Go to 3D Print Lab
                </Button>
              )}
            </Flex>
          </>
        )}
        <CreateCartItem></CreateCartItem>
      </Paper>
    </Container>
  );
};

export default ShopM;