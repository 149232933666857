import React, { useState, useEffect, useCallback } from 'react';
import apiClient from '../axiosConfig'; // adjust the import path as needed

const ShopS = () => {
  const [models, setModels] = useState([]);
  const [page, setPage] = useState(1);
  const [materials, setMaterials] = useState([]);
  const [selectedMaterial, setSelectedMaterial] = useState('');
  const [uploadedModel, setUploadedModel] = useState(null);

  const fetchModels = useCallback(async () => {
    console.log('Fetching models for page:', page);
    try {
      const response = await apiClient.get(`/shapeways/list-models?page=${page}`);
      console.log('Fetched models response:', response.data);
      if (response.data && response.data.models) {
        setModels(response.data.models);
      } else {
        console.log('No models found');
        setModels([]);
      }
    } catch (error) {
      console.error('Error fetching models:', error);
      setModels([]);
    }
  }, [page]);

  const fetchMaterials = async () => {
    console.log('Fetching materials');
    try {
      const response = await apiClient.get('/shapeways/materials');
      console.log('Fetched materials:', response.data.materials);
      setMaterials(response.data.materials || []);
    } catch (error) {
      console.error('Error fetching materials:', error);
      setMaterials([]);
    }
  };

  useEffect(() => {
    fetchMaterials();
  }, []);

  useEffect(() => {
    fetchModels();
  }, [page, fetchModels]);

  const uploadModel = async () => {
    console.log('Triggering model upload');
    try {
      const response = await apiClient.post('/shapeways/upload-model');
      console.log('Model uploaded:', response.data);
      setUploadedModel(response.data);
      fetchModels();
    } catch (error) {
      console.error('Error uploading model:', error);
    }
  };

  const handlePurchase = async () => {
    if (!uploadedModel || !selectedMaterial) return;

    const orderData = {
      items: [{
        modelId: uploadedModel.modelId,
        materialId: selectedMaterial,
        quantity: 1
      }],
      firstName: 'John', // Replace with actual user data
      lastName: 'Doe',
      country: 'US',
      state: 'NY',
      city: 'New York',
      address1: '123 Main St',
      zipCode: '10001',
      phoneNumber: '1234567890',
      shippingOption: 'Cheapest',
      paymentMethod: 'credit_card'
    };

    try {
      const response = await apiClient.post('/shapeways/place-order', orderData, {
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        }
      });
      console.log('Order placed:', response.data);
      // Handle successful order (e.g., show confirmation, redirect, etc.)
    } catch (error) {
      console.error('Error placing order:', error);
    }
  };

  return (
    <div className="shop-s">
      <h1>Shapeways Shop</h1>
      <button onClick={uploadModel}>Upload Model</button>
      {uploadedModel && (
        <div>
          <p>Model uploaded successfully! ID: {uploadedModel.modelId}</p>
          <select onChange={(e) => setSelectedMaterial(e.target.value)}>
            <option value="">Select Material</option>
            {Object.entries(materials).map(([id, material]) => (
              <option key={id} value={id}>{material.title}</option>
            ))}
          </select>
          <button onClick={handlePurchase}>Purchase</button>
        </div>
      )}
      <h2>Your Models</h2>
      <ul>
        {models.length > 0 ? (
          models.map((model) => (
            <li key={model.modelId}>
              {model.title || 'Untitled'} (ID: {model.modelId})
            </li>
          ))
        ) : (
          <li>No models found</li>
        )}
      </ul>
      <button onClick={() => setPage(prev => Math.max(1, prev - 1))} disabled={page === 1}>
        Previous Page
      </button>
      <button onClick={() => setPage(prev => prev + 1)}>
        Next Page
      </button>
    </div>
  );
};

export default ShopS;
