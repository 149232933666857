// src/App.js
import React, { useState, useEffect } from 'react';
import { MantineProvider, Container } from '@mantine/core';
import { Elements } from '@stripe/react-stripe-js';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import stripePromise from './stripe';
import LandingTabs from './components/LandingTabs';
import ShopM from './components/ShopM';
import ShopS from './components/ShopS';
import Callback from './components/callback';
import axios from './axiosConfig';
import { CountryProvider } from './contexts/CountryContext'; // Import the CountryProvider

function App() {
  const [user, setUser] = useState(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState(null);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchUserData();
    }

    const urlParams = new URLSearchParams(window.location.search);
    const subscription = urlParams.get('subscription');
    if (subscription === 'success') {
      setSubscriptionStatus('success');
    } else if (subscription === 'canceled') {
      setSubscriptionStatus('canceled');
    }
    // Clear the URL parameters after processing
    window.history.replaceState({}, document.title, window.location.pathname);
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('No token found, user needs to login');
      return;
    }
    
    try {
      const response = await axios.get('/auth/me');
      console.log('User data fetched:', response.data);
      setUser(response.data);
    } catch (error) {
      console.error('Failed to fetch user data', error);
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        console.log('Token invalid, logging out');
        handleLogout();
      }
    }
  };

  const handleLogin = (data) => {
    console.log('Login data received:', data);
    setUser(data.user);
    localStorage.setItem('token', data.token);
    axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
  }

  const handleLogout = () => {
    setUser(null);
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <MantineProvider withGlobalStyles withNormalizeCSS>
      <Notifications position="top-right" zIndex={3000} />
      <Elements stripe={stripePromise}>
      <CountryProvider>
        <Router>
          <Container size="xl" px="xs">
            <Routes>
              <Route path="/" element={
                <LandingTabs 
                  user={user} 
                  onLogin={handleLogin} 
                  onLogout={handleLogout} 
                  subscriptionStatus={subscriptionStatus}
                />
              } />
              <Route path="/callback" element={<Callback />} />
              <Route path="/shop-m" element={<ShopM user={user} />} />
              <Route path="/shop-s" element={<ShopS user={user} />} />
              {/* Add other routes as needed */}
            </Routes>
          </Container>
        </Router>
        </CountryProvider>
      </Elements>
    </MantineProvider>
  );
}

export default App;
