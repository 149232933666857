import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Callback() {
  const navigate = useNavigate();

  useEffect(() => {
    // Here you would typically exchange the code for tokens
    // and set up the user's session
    console.log('Handling Auth0 callback');
    // For now, let's just redirect to the home page
    navigate('/');
  }, [navigate]);

  return <div>Processing authentication...</div>;
}

export default Callback;