// src/axiosConfig.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://f-oracle-backend.onrender.com/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

apiClient.interceptors.request.use(
  config => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    console.log('Full request URL:', config.baseURL + config.url);
    console.log('Request headers:', config.headers);
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  response => {
    console.log('Response:', response.status, response.config.url);
    console.log('Response data:', response.data);
    return response;
  },
  error => {
    console.error('Response error:', error.response?.status, error.response?.data, error.config.url);
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      localStorage.removeItem('token');
      // Optionally, redirect to login page or dispatch a logout action
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Remove this part as it's redundant with the request interceptor
// const token = localStorage.getItem('token');
// if (token) {
//   apiClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
// }

export default apiClient;