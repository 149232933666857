// src/components/NewSearch.js

import React, { useState, useEffect } from 'react';
import { Box, Title, Button, Select, LoadingOverlay, Text } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import axios from '../../src/axiosConfig';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import Coordinates from './Coordinates';
import { DateTime } from 'luxon';
import tzlookup from 'tz-lookup';

function NewSearch({ user, onHoroscopeCreated }) {
  const [horoscopeData, setHoroscopeData] = useState({
    horoscopeType: '',
    inputDate: new Date(),
    birthDate: null,
    longitude: '',
    latitude: '',
  });

  const [loading, setLoading] = useState(false);
  const [inputDateTimeInfo, setInputDateTimeInfo] = useState(null);
  const [birthDateTimeInfo, setBirthDateTimeInfo] = useState(null);

  useEffect(() => {
    updateTimeInfo();
  }, [horoscopeData.inputDate, horoscopeData.birthDate, horoscopeData.longitude, horoscopeData.latitude]);

  const updateTimeInfo = () => {
    if (horoscopeData.longitude && horoscopeData.latitude) {
      setInputDateTimeInfo(getTimeInfo(horoscopeData.latitude, horoscopeData.longitude, horoscopeData.inputDate));
      if (horoscopeData.birthDate) {
        setBirthDateTimeInfo(getTimeInfo(horoscopeData.latitude, horoscopeData.longitude, horoscopeData.birthDate));
      }
    }
  };

  const getTimeInfo = (latitude, longitude, date) => {
    if (!date) return null;
    
    const timeZone = getTimeZoneFromCoordinates(latitude, longitude);
    const dateTime = DateTime.fromJSDate(date).setZone(timeZone);
    
    const offset = dateTime.offset / 60; // Convert minutes to hours
    const offsetString = offset >= 0 ? `+${offset}` : `${offset}`;
    
    return {
      isDST: dateTime.isInDST,
      offset: offsetString,
      zoneName: dateTime.zoneName
    };
  };

  const getTimeZoneFromCoordinates = (latitude, longitude) => {
    try {
      return tzlookup(parseFloat(latitude), parseFloat(longitude));
    } catch (error) {
      console.error('Error looking up time zone:', error);
      showNotification({
        title: 'Error',
        message: 'Failed to determine time zone. Using UTC as fallback.',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return 'UTC'; // Fallback to UTC if lookup fails
    }
  };

  const handleHoroscopeChange = (field) => (value) => {
    setHoroscopeData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleCoordinatesChange = (latitude, longitude) => {
    setHoroscopeData(prevData => ({ ...prevData, latitude, longitude }));
  };

  const handleCreateHoroscope = async () => {
    if (!horoscopeData.horoscopeType || !horoscopeData.inputDate || !horoscopeData.birthDate || !horoscopeData.longitude || !horoscopeData.latitude) {
      showNotification({
        title: 'Error',
        message: 'Please fill in all fields',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post('/saved-horoscopes/generate', {
        horoscope_type: horoscopeData.horoscopeType,
        input_date: horoscopeData.inputDate.toISOString(),
        birth_date: horoscopeData.birthDate.toISOString(),
        longitude: parseFloat(horoscopeData.longitude),
        latitude: parseFloat(horoscopeData.latitude),
      });
  
      console.log('Horoscope created successfully', response.data);
      showNotification({
        title: 'Success',
        message: 'Your horoscope has been created and saved',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
      setHoroscopeData({
        horoscopeType: '',
        inputDate: new Date(),
        birthDate: null,
        longitude: '',
        latitude: '',
      });
      if (onHoroscopeCreated) {
        onHoroscopeCreated();
      }
    } catch (error) {
      console.error('Horoscope creation failed', error);
      showNotification({
        title: 'Error',
        message: 'Horoscope creation failed: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box position="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Title order={2}>Create Horoscope</Title>
      <Box p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
        <Select
          label="Horoscope Type"
          placeholder="Select horoscope type"
          data={[
            { value: 'natal', label: 'Natal' },
            { value: 'transit', label: 'Transit' },
            { value: 'personal', label: 'Personal' },
          ]}
          value={horoscopeData.horoscopeType}
          onChange={handleHoroscopeChange('horoscopeType')}
        />
        <DateTimePicker
          label="Input Date and Time"
          placeholder="Pick date and time"
          value={horoscopeData.inputDate}
          onChange={handleHoroscopeChange('inputDate')}
          clearable
        />
        {inputDateTimeInfo && (
          <Text size="sm" color="dimmed">
            Time zone: {inputDateTimeInfo.zoneName} (UTC{inputDateTimeInfo.offset}). 
            DST is {inputDateTimeInfo.isDST ? 'in effect' : 'not in effect'} for this date and location.
          </Text>
        )}
        <DateTimePicker
          label="Birth Date and Time"
          placeholder="Pick date and time"
          value={horoscopeData.birthDate}
          onChange={handleHoroscopeChange('birthDate')}
          clearable
        />
        {birthDateTimeInfo && (
          <Text size="sm" color="dimmed">
            Time zone: {birthDateTimeInfo.zoneName} (UTC{birthDateTimeInfo.offset}). 
            DST is {birthDateTimeInfo.isDST ? 'in effect' : 'not in effect'} for this date and location.
          </Text>
        )}
        <Coordinates
          initialLongitude={horoscopeData.longitude}
          initialLatitude={horoscopeData.latitude}
          onCoordinatesChange={handleCoordinatesChange}
        />
        <Button onClick={handleCreateHoroscope} mt="md" fullWidth>Create Horoscope</Button>
      </Box>
    </Box>
  );
}

export default NewSearch;