// src/components/SavedCharts.js

import React, { useState, useEffect } from 'react';
import { Box, Title, Button, Stack, Select, LoadingOverlay } from '@mantine/core';
import { DateTimePicker } from '@mantine/dates';
import axios from '../../src/axiosConfig';
import { showNotification } from '@mantine/notifications';
import { IconCheck, IconX } from '@tabler/icons-react';
import SavedHoroscopeDisplay from './SavedHoroscopeDisplay';
import Coordinates from './Coordinates';  // Import the Coordinates component

function SavedCharts({ user }) {
  const [horoscopeData, setHoroscopeData] = useState({
    horoscopeType: '',
    inputDate: new Date(),
    birthDate: null,
    longitude: '',
    latitude: '',
  });

  const [savedHoroscopes, setSavedHoroscopes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (user) {
      fetchSavedHoroscopes();
    }
  }, [user]);

  const fetchSavedHoroscopes = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/saved-horoscopes');
      setSavedHoroscopes(response.data);
    } catch (error) {
      console.error('Failed to fetch saved horoscopes', error);
      showNotification({
        title: 'Error',
        message: 'Failed to fetch saved horoscopes: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleHoroscopeChange = (field) => (value) => {
    setHoroscopeData(prevData => ({ ...prevData, [field]: value }));
  };

  const handleCoordinatesChange = (latitude, longitude) => {
    setHoroscopeData(prevData => ({ ...prevData, latitude, longitude }));
  };

  const handleCreateHoroscope = async () => {
    if (!horoscopeData.horoscopeType || !horoscopeData.inputDate || !horoscopeData.birthDate || !horoscopeData.longitude || !horoscopeData.latitude) {
      showNotification({
        title: 'Error',
        message: 'Please fill in all fields',
        color: 'red',
        icon: <IconX size={18} />,
      });
      return;
    }
  
    setLoading(true);
    try {
      const response = await axios.post('/saved-horoscopes/generate', {
        horoscope_type: horoscopeData.horoscopeType,
        input_date: horoscopeData.inputDate.toISOString(),
        birth_dates: horoscopeData.birthDate.toISOString(),
        longitude: parseFloat(horoscopeData.longitude),
        latitude: parseFloat(horoscopeData.latitude),
      });
  
      console.log('Horoscope created successfully', response.data);
      showNotification({
        title: 'Success',
        message: 'Your horoscope has been created and saved',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
      setHoroscopeData({
        horoscopeType: '',
        inputDate: new Date(),
        birthDate: null,
        longitude: '',
        latitude: '',
      });
      fetchSavedHoroscopes();
    } catch (error) {
      console.error('Horoscope creation failed', error);
      showNotification({
        title: 'Error',
        message: 'Horoscope creation failed: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteHoroscope = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/saved-horoscopes/${id}`);
      showNotification({
        title: 'Success',
        message: 'Horoscope deleted successfully',
        color: 'teal',
        icon: <IconCheck size={18} />,
      });
      fetchSavedHoroscopes();
    } catch (error) {
      console.error('Failed to delete horoscope', error);
      showNotification({
        title: 'Error',
        message: 'Failed to delete horoscope: ' + (error.response?.data?.message || error.message),
        color: 'red',
        icon: <IconX size={18} />,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box position="relative">
      <LoadingOverlay visible={loading} overlayBlur={2} />
      <Title order={2}>Create Horoscope</Title>
      <Box p="md" style={{ border: '1px solid #ddd', borderRadius: '4px' }}>
        <Select
          label="Horoscope Type"
          placeholder="Select horoscope type"
          data={[
            { value: 'natal', label: 'Natal' },
            { value: 'transit', label: 'Transit' },
            { value: 'personal', label: 'Personal' },
          ]}
          value={horoscopeData.horoscopeType}
          onChange={handleHoroscopeChange('horoscopeType')}
        />
        <DateTimePicker
          label="Input Date and Time"
          placeholder="Pick date and time"
          value={horoscopeData.inputDate}
          onChange={handleHoroscopeChange('inputDate')}
          clearable
        />
        <DateTimePicker
          label="Birth Date and Time"
          placeholder="Pick date and time"
          value={horoscopeData.birthDate}
          onChange={handleHoroscopeChange('birthDate')}
          clearable
        />
        <Coordinates
          initialLongitude={horoscopeData.longitude}
          initialLatitude={horoscopeData.latitude}
          onCoordinatesChange={handleCoordinatesChange}
        />
        <Button onClick={handleCreateHoroscope} mt="md" fullWidth>Create Horoscope</Button>
      </Box>

      <Title order={2} mt="xl">Saved Horoscopes</Title>
      <SavedHoroscopeDisplay 
        savedHoroscopes={savedHoroscopes} 
        handleDeleteHoroscope={handleDeleteHoroscope} 
      />
    </Box>
  );
}

export default SavedCharts;